///
/// @file template_api/products/product_store_check_layout_v1/src/product_store_check_layout_v1.scss
///
/// \brief Styles for product product store check layout - v1
///
///

@import '../../../../scss/theme-bootstrap';

.instore-inventory-container {
  .inline {
    &-container {
      &__link {
        display: none;
        margin-top: 10px;
      }
      &__controls {
        padding: 10px 0px;
      }
      &__doors {
        .doors-container {
          max-height: 240px;
          overflow-y: auto;
        }
      }
    }
  }
}

.inline {
  &-container {
    &__available {
      &--doors {
        font-weight: bold;
        padding: 20px 0px 10px;
      }
    }
  }
}

.doors-avail {
  display: block;
  .container-row {
    &__doors {
      &--msg {
        display: none;
      }
    }
  }
}

.location-controls {
  &__hdr {
    font-weight: 700;
    display: inline-block;
    padding-#{$rdirection}: 8px;
    font-size: 17px;
  }
  &__zip {
    width: 100%;
  }
  &__input {
    width: 60%;
    padding: 10px;
    @include breakpoint($small-down) {
      width: 80%;
    }
    @include breakpoint($iphone5-portrait) {
      width: 74%;
      margin-#{$rdirection}: 5px;
      height: 40px;
    }
  }
  &__btn {
    min-width: 23%;
    line-height: 19px;
    background-color: $color-off-black;
    color: #fff;
    border: solid 1px $white;
    -moz-box-shadow: 0 0 0 1px $current-black;
    -webkit-box-shadow: 0 0 0 1px $current-black;
    box-shadow: 0 0 0 1px $current-black;
    letter-spacing: 0.05em;
    padding: 10px 15px;
    margin-#{$ldirection}: 3px;
    cursor: pointer;
    @include breakpoint($small-down) {
      width: 18%;
      display: inline-block;
      text-align: center;
      padding: 8px;
      margin-#{$ldirection}: 1%;
      min-width: 0px;
    }
    @include breakpoint($iphone5-portrait) {
      width: 21%;
      min-width: 0px;
      padding: 0px;
      height: 38px;
      line-height: 40px;
    }
    &:hover {
      text-decoration: none;
      color: $black;
      background-color: $white;
    }
  }
  &__distance {
    display: inline-block;
    &--select {
      margin-#{$ldirection}: 33px;
      margin-#{$rdirection}: 7px;
    }
  }
  &__info {
    display: inline-block;
    padding-top: 10px;
    color: $color-gray;
  }
  &__close {
    position: absolute;
    top: 5px;
    #{$rdirection}: 0px;
  }
}

.inline-errors {
  &__error {
    display: none;
    &.active {
      text-align: $ldirection;
      display: block;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}

.product-store-check {
  &__inline {
    &--container {
      border-top: $border-black;
      border-bottom: $border-black;
      margin-top: 10px;
    }
  }
  &__controls {
    &--controls {
      position: relative;
    }
  }
  .inline-container {
    &__available--doors {
      display: none;
      @include breakpoint($medium-up) {
        display: block;
      }
    }
  }
}

.door-row__info {
  width: 100%;
}

.door-info {
  &__address {
    width: 60%;
    float: $ldirection;
    &--name {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__avail {
    width: 40%;
    float: $ldirection;
    padding-#{$ldirection}: 10px;
  }
}

.avail-info {
  &__msg {
    &--span {
      border-radius: 50%;
      display: inline-block;
      height: 0.75em;
      width: 0.75em;
      margin: 0.15em 0.25em 0 0;
      padding: 0.2em;
      vertical-align: top;
      vertical-align: inherit;
    }
    &--text {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.msg-span {
  &__avail {
    background-color: $color-green;
  }
  &__not-avail {
    background-color: $light-red;
  }
  &__call {
    background-color: $color-yellow;
  }
}

.container-row {
  &__doors {
    &--msg {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.2;
      padding-top: 10px;
    }
  }
}

.door-row {
  &__info {
    padding: 10px 0px;
  }
}

.door-info {
  &__address {
    &--addr {
      display: none;
    }
    &--detail,
    &--distance,
    &--phone {
      font-size: 14px;
    }
  }
}

//psc-state-overlay style for mobile
.product-store-check__overlay {
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  #{$ldirection}: 0;
  background-color: $white;
  z-index: 1000;
  overflow-y: scroll;
  &--container {
    padding: 6%;
    .location-controls {
      text-align: center;
      border-bottom: $border-black;
      padding: 0 0 1em;
      &__hdr {
        text-align: $ldirection;
        width: 100%;
        border-bottom: $border-black;
        padding: 0 0 0.5em;
        font-size: 20px;
      }
      &__input {
        &--zip {
          width: 70%;
          border-radius: 0;
          background: 0;
          float: $ldirection;
          padding: 8px;
        }
      }
      &__btn {
        &--zip {
          float: $rdirection;
          min-width: 10%;
        }
      }
      &__zip {
        margin: 1em 0 0.75em;
      }
      &__info {
        margin: 0;
        float: $ldirection;
        top: 10px;
        position: relative;
      }
      &__distance {
        position: relative;
        float: $rdirection;
        width: 4.25em;
        &--select {
          height: 30px;
          line-height: 26px;
          padding: 0px;
          text-transform: lowercase;
          margin-#{$ldirection}: 0px;
          margin-#{$rdirection}: 0px;
        }
      }
      &__close {
        position: absolute;
        #{$rdirection}: 0px;
        top: 5px;
      }
    }
    .inline-container {
      &__available--doors {
        display: block;
      }
    }
  }
  .door {
    &-row {
      padding: 10px 0px;
      border-bottom: $border-black;
    }
  }
}

// State styles for all devices
.psc-state-init {
  .inline-container {
    &__link {
      display: inline-block;
      font-size: 15px;
    }
  }
  .container-row {
    &__doors {
      &--msg {
        display: none;
      }
    }
  }
}

.psc-state-error {
  .overlay-container {
    .location-controls {
      &__info {
        display: none;
      }
    }
    .container-row {
      &__doors {
        display: none;
      }
    }
  }
  .doors-avail {
    display: none;
  }
}

.aveda_salon {
  text-decoration: underline;
}

.ajax-wait-inventory {
  .location-controls {
    &__loading {
      text-align: center;
      margin-top: 10px;
    }
  }
}
